import React, { useEffect, useRef, useState } from 'react'
import ConstantConsumptionStyle from './ConstantConsumption'
import Button from '../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { logsUrl } from '../../../../api'
import Layout from '../../../../components/Layout'
import { ErrorModal } from '../../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import ConfirmModal from '../../../../components/ComfirmModal'
import { get } from 'lodash'
import { COMPANY_NAME } from '../../../../config'
import moment from 'moment/moment'
import { useSelector } from 'react-redux' // Adjust the import path accordingly

const ConstantConsumptionPage = () => {
	const { getMe } = useSelector((state) => state.main)
	const navigation = useNavigate()
	const inputRef = useRef(null)
	const [isLoading, setIsLoading] = useState(false)
	const [date, setDate] = useState(new Date().toISOString().split('T')[0])
	const errorRef = useRef()
	const [currency, setCurrency] = useState('USD')
	const [cashAccountsData, setCashAccountsData] = useState([])
	const [spendAccountsData, setSpendAccountsData] = useState([])
	const [formData, setFormData] = useState({
		CardCode: '',
		CashAccount: '',
		CashSum: 0,
		AccountCode: '',
	})
	const [btnLoading, setBtnLoading] = useState(false)
	const [remarks, setRemarks] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')

	const handleSpendAccountClick = (event) => {
		if (inputRef.current) {
			inputRef.current.value = event.target.innerText
		}
		setFormData((prev) => ({
			...prev,
			CardCode: event.target.value,
		}))
	}

	const cashAccounts = async () => {
		const data = await api(
			`ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '5') and ExternalCode eq '1' and (AcctCurrency eq '${currency}' or AcctCurrency eq '%23%23')`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setCashAccountsData(data)
		setFormData((prev) => ({
			...prev,
			CashAccount: data[0]?.Code,
		}))
	}

	const typeSpend = async () => {
		const data = await api(
			`ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '94') and ExternalCode eq '1' and (AcctCurrency eq '${currency}' or AcctCurrency eq '%23%23')`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setSpendAccountsData(data)
		setFormData((prev) => ({
			...prev,
			CardCode: data[0]?.Code,
		}))
	}

	useEffect(() => {
		cashAccounts()
		typeSpend()
	}, [currency])

	const submitForm = () => {
		setConfirmationMessage([
			{
				name: 'Дата:',
				value: date,
			},
			{
				name: 'Валюта:',
				value: currency,
			},
			{
				name: 'Денежный счет:',
				value: `${cashAccountsData.find((item) => item.Code === formData.CashAccount).Name}, ${formData.CashAccount}`,
			},
			{
				name: 'Счет расхода:',
				value: `${spendAccountsData.find((item) => item.Code === formData.CardCode).Name}, ${formData.CardCode}`,
			},
			{
				name: 'Сумма:',
				value: `${formData.CashSum}`,
			},
			{
				name: 'Комментарий:',
				value: `${remarks ? remarks : 'Нет'}`,
			},
		])
		setShowModal(true)
	}

	const handleConfirm = async () => {
		setShowModal(false)
		setBtnLoading(true)

		try {
			const response = await api
				.post('VendorPayments', {
					DocDate: date + 'T00:00:00Z',
					DocType: 'rAccount',
					CardCode: formData.CardCode,
					CashAccount: formData.CashAccount,
					Remarks: remarks ? remarks : null,
					DocCurrency: currency,
					CashSum: formData.CashSum,
					PaymentAccounts: [
						{
							AccountCode: formData.CardCode,
							SumPaid: formData.CashSum,
						},
					],
				})
				.then((res) => {
					try {
						logsUrl.post('apis', {
							company_name: COMPANY_NAME,
							method: 'post',
							path: `/VendorPayments`,
							description: 'Создать постоянные расход',
							username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
							body: JSON.stringify({
								DocDate: date + 'T00:00:00Z',
								DocType: 'rAccount',
								CardCode: formData.CardCode,
								CashAccount: formData.CashAccount,
								Remarks: remarks ? remarks : null,
								DocCurrency: currency,
								CashSum: formData.CashSum,
								PaymentAccounts: [
									{
										AccountCode: formData.CardCode,
										SumPaid: formData.CashSum,
									},
								],
							}),
						})
					} catch (err) {
						errorRef.current?.open(
							'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
						)
					}
				})
			navigation('/outgoing-payment')
			return JSON.parse(response.data).value
		} catch (err) {
			if (isAxiosError(err)) {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			}
		} finally {
			setBtnLoading(false)
		}
	}

	const handleCancel = () => {
		setShowModal(false)
	}

	const handleDateChange = (event) => {
		setDate(event.target.value)
	}

	const handleCurrencyChange = (event) => {
		setCurrency(event.target.value)
		setSpendAccountsData([])
		setCashAccountsData([])
		if (inputRef.current) {
			inputRef.current.value = ''
		}
		setFormData((prev) => ({
			...prev,
			CashSum: null,
			CardCode: null,
		}))
	}

	const handleClickAccountCode = (event) => {
		setFormData((prev) => ({
			...prev,
			CashAccount: event.target.value,
		}))
	}

	const handleRemarks = (event) => {
		setRemarks(event.target.value)
	}

	return (
		<Layout>
			<ConstantConsumptionStyle>
				<div className="container">
					<p className="productTitle text-xl font-bold text-center py-8">
						Создать постоянные расход
					</p>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<div>
							<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-6">
								<div className="flex flex-col">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Валюта
									</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										onChange={handleCurrencyChange}
									>
										<option value="USD">USD</option>
										<option value="UZS">UZS</option>
									</select>
								</div>
								<div className="flex flex-col">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Счет расхода
									</p>
									<select
										value={formData.CardCode}
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										onChange={handleSpendAccountClick}
									>
										{spendAccountsData.map((item) => {
											return (
												<option key={item.Code} value={item.Code}>
													{item.Name} - {item.Code}
												</option>
											)
										})}
									</select>
								</div>
								<div className="flex flex-col">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Дата
									</p>
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										type="date"
										defaultValue={date}
										onChange={handleDateChange}
									/>
								</div>
							</div>
							<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-3">
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Денежный счет
									</p>
									<select
										value={formData.CashAccount}
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										onChange={handleClickAccountCode}
									>
										{cashAccountsData.map((item) => {
											return (
												<option key={item.Code} value={item.Code}>
													{item.Name} - {item.Code}
												</option>
											)
										})}
									</select>
								</div>
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Сумма
									</p>
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										type="number"
										onChange={(e) =>
											setFormData((prev) => ({
												...prev,
												CashSum: parseFloat(e.target.value),
											}))
										}
										value={formData.CashSum}
									/>
								</div>
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Комментарий
									</p>
									<textarea
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										onChange={handleRemarks}
									/>
								</div>
							</div>
							<div className="flex justify-center py-4">
								<Button
									onClick={submitForm}
									loading={btnLoading}
									disabled={
										!formData.CashAccount ||
										!formData.CardCode ||
										!(+formData.CashSum > 0)
									}
								>
									Отправить
								</Button>
							</div>
						</div>
					)}
				</div>
			</ConstantConsumptionStyle>

			<ConfirmModal
				show={showModal}
				data={confirmationMessage}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			/>
		</Layout>
	)
}

export default ConstantConsumptionPage
