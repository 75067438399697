import React, { useEffect, useRef, useState } from 'react'
import PaySupplier from './PaySupplier'
import Button from '../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi, logsUrl } from '../../../../api'
import _, { get } from 'lodash'
import Layout from '../../../../components/Layout'
import { ErrorModal } from '../../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'
import { roundCurrency } from '../../../../utils'
import { COMPANY_NAME } from '../../../../config'
import { useSelector } from 'react-redux'
import ConfirmModal from '../../../../components/ComfirmModal'

const PaymentSupplierPage = () => {
	const { getMe } = useSelector((state) => state.main)
	const navigation = useNavigate()
	const inputRef = useRef(null)
	const [isLoading, setIsLoading] = useState(false)
	const [date, setDate] = useState(new Date().toISOString().split('T')[0])
	const errorRef = useRef()
	const [currency, setCurrency] = useState('UZS')
	const [cashAccountsData, setCashAccountsData] = useState([])
	const [formData, setFormData] = useState({
		CardCode: '',
		CashAccount: '',
	})
	const [debtsData, setDebtsData] = useState([])
	const [remarks, setRemarks] = useState(null)
	const [businessPartnersData, setBusinessPartnersData] = useState([])
	const [paymentInvoices, setPaymentInvoices] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')
	const [loading, setLoading] = useState(false)

	const handleOpenModal = async () => {
		setLoading(true)
		let cashSum = 0
		for (const key in paymentInvoices) {
			if (paymentInvoices.hasOwnProperty(key)) {
				cashSum += +paymentInvoices[key].SumApplied
			}
		}

		const processedPaymentInvoices = []
		let rate = 1

		if (currency === 'UZS') {
			await api
				.post(`SBOBobService_GetCurrencyRate`, {
					Currency: 'UZS',
					Date: moment(date).format('YYYY-MM-DD'),
				})
				.then((res) => {
					rate = res.data
				})
		}

		for (const key in paymentInvoices) {
			if (paymentInvoices.hasOwnProperty(key)) {
				if (paymentInvoices[key].SumApplied > 0) {
					if (currency === 'USD') {
						processedPaymentInvoices.push({
							DocEntry: paymentInvoices[key].DocEntry,
							SumApplied: +paymentInvoices[key].SumApplied,
							InvoiceType: 'it_PurchaseInvoice',
						})
					} else {
						processedPaymentInvoices.push({
							DocEntry: paymentInvoices[key].DocEntry,
							SumApplied: roundCurrency(
								+paymentInvoices[key].SumApplied / +rate,
							),
							InvoiceType: 'it_PurchaseInvoice',
						})
					}
				}
			}
		}

		setConfirmationMessage([
			{
				name: 'Дата:',
				value: moment(date).format('DD-MM-YYYY'),
			},
			{
				name: 'Валюта:',
				value: currency,
			},
			{
				name: 'Код Поставщика:',
				value: formData.CardCode,
			},
			{
				name: 'Счот:',
				value: formData.CashAccount,
			},
			{
				name: 'Сумма наличных:',
				value: cashSum,
			},
			{
				name: 'Обработанные платежные счета:',
				value: JSON.stringify(processedPaymentInvoices),
			},
			{
				name: 'Комментарий:',
				value: `${remarks ? remarks : 'Нет'}`,
			},
		])
		setShowModal(true)
		setLoading(false)
	}

	const getBusinessPartners = async (name = '') => {
		if (name.length <= 0) {
			setBusinessPartnersData([])
			return
		}

		const data = await api(
			`BusinessPartners?$select=CardCode,CardName&$filter=CardType eq 'cSupplier' and contains(CardName,'${name}')`,
		).then((res) => {
			return JSON.parse(res.data).value
		})

		const selectElementData = data.map((item) => {
			return {
				value: item.CardCode,
				label: item.CardName,
			}
		})

		setBusinessPartnersData(selectElementData)
	}

	const handleBusinessPartner = _.debounce((e) => {
		getBusinessPartners(e.target.value)
	}, 2000)

	const handleBusinessPartnerClick = (event, code) => {
		if (inputRef.current) {
			inputRef.current.value = event.target.innerText
		}
		setFormData((prev) => ({
			...prev,
			CardCode: code,
		}))
		setBusinessPartnersData([])
	}

	const cashAccounts = async () => {
		const data = await api(
			`ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '5') and ExternalCode eq '1' and (AcctCurrency eq '${currency}' or AcctCurrency eq '%23%23')`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setCashAccountsData(data)
		setFormData((prev) => ({
			...prev,
			CashAccount: data[0]?.Code,
		}))
	}

	useEffect(() => {
		cashAccounts()
	}, [currency])

	const debts = async () => {
		const data = await api(
			`PurchaseInvoices?$select=DocEntry,DocTotal,PaidToDate,DocCurrency&$filter=DocumentStatus eq 'bost_Open' and CardCode eq '${formData.CardCode}' and Cancelled eq 'tNO'`,
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setDebtsData(data)
	}

	useEffect(() => {
		if (formData.CardCode.length > 0) debts()
	}, [formData.CardCode])

	const submitForm = async () => {
		try {
			let cashSum = 0
			for (const key in paymentInvoices) {
				if (paymentInvoices.hasOwnProperty(key)) {
					cashSum += +paymentInvoices[key].SumApplied
				}
			}

			const processedPaymentInvoices = []
			let rate = 1

			if (currency === 'UZS') {
				await api
					.post(`SBOBobService_GetCurrencyRate`, {
						Currency: 'UZS',
						Date: moment(date).format('YYYY-MM-DD'),
					})
					.then((res) => {
						rate = res.data
					})
			}

			for (const key in paymentInvoices) {
				if (paymentInvoices.hasOwnProperty(key)) {
					if (paymentInvoices[key].SumApplied > 0) {
						if (currency === 'USD') {
							processedPaymentInvoices.push({
								DocEntry: paymentInvoices[key].DocEntry,
								SumApplied: +paymentInvoices[key].SumApplied,
								InvoiceType: 'it_PurchaseInvoice',
							})
						} else {
							processedPaymentInvoices.push({
								DocEntry: paymentInvoices[key].DocEntry,
								SumApplied: roundCurrency(
									+paymentInvoices[key].SumApplied / +rate,
								),
								InvoiceType: 'it_PurchaseInvoice',
							})
						}
					}
				}
			}

			await api
				.post(`VendorPayments`, {
					DocDate: date + 'T00:00:00Z',
					DocType: 'rSupplier',
					CardCode: formData.CardCode,
					CashAccount: formData.CashAccount,
					Remarks: remarks && null,
					DocCurrency: currency,
					CashSum: cashSum,
					PaymentInvoices: processedPaymentInvoices,
				})
				.then((res) => {
					try {
						logsUrl.post('apis', {
							company_name: COMPANY_NAME,
							method: 'post',
							path: `/VendorPayments`,
							description: 'kassada isxodayishi platyojda oplatit postavshik',
							username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
							body: JSON.stringify({
								DocDate: date + 'T00:00:00Z',
								DocType: 'rSupplier',
								CardCode: formData.CardCode,
								CashAccount: formData.CashAccount,
								Remarks: remarks && null,
								DocCurrency: currency,
								CashSum: cashSum,
								PaymentInvoices: processedPaymentInvoices,
							}),
						})
					} catch (err) {
						errorRef.current?.open(
							'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
						)
					}
				})
			navigation('/outgoing-payment')
		} catch (err) {
			if (isAxiosError(err)) {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			}
		}
	}

	const handleDateChange = (event) => {
		setDate(event.target.value)
	}

	const handleCurrencyChange = (event) => {
		setCurrency(event.target.value)
	}

	const handleClickCashAccount = (event) => {
		setFormData((prev) => ({
			...prev,
			CashAccount: event.target.value,
		}))
	}

	const handleRemarks = (event) => {
		setRemarks(event.target.value)
	}

	const handlePayingSum = (event, DocEntry) => {
		setPaymentInvoices((prev) => ({
			...prev,
			[DocEntry]: { DocEntry, SumApplied: event.target.value },
		}))
	}

	return (
		<Layout>
			<PaySupplier>
				<div className="container p-8">
					<p className="productTitle text-xl font-bold mb-8">
						Создать оплата поставщику
					</p>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<div>
							<div
								className={'flex flex-col md:flex-row gap-5 items-center mb-7'}
							>
								<div className="searchable-select flex-1">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Код Поставщика
									</p>
									<input
										className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md '}
										ref={inputRef}
										type="text"
										onChange={handleBusinessPartner}
									/>
									{!!businessPartnersData.length && (
										<div className="searchable-select-list">
											{businessPartnersData.map((item) => {
												return (
													<div
														key={item.value}
														className="searchable-select-item"
														onClick={(event) =>
															handleBusinessPartnerClick(event, item.value)
														}
													>
														{item.label}
													</div>
												)
											})}
										</div>
									)}
								</div>

								<div className={'flex-1'}>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Дата
									</p>
									<input
										className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md'}
										type="date"
										defaultValue={date}
										onChange={handleDateChange}
									/>
								</div>
							</div>
							<div>
								<table
									className={
										'w-full text-sm text-left rtl:text-right text-gray-500  mb-4'
									}
								>
									<thead
										className={'text-xs text-gray-700 uppercase bg-gray-50'}
									>
										<tr>
											<th scope="col" className="px-6 py-3">
												документ
											</th>
											<th scope="col" className="px-6 py-3">
												Сумма
											</th>
											<th scope="col" className="px-6 py-3">
												Примененная сумма
											</th>
										</tr>
									</thead>
									<tbody>
										{debtsData.map((item) => {
											return (
												<tr className="bg-white border-b " key={item.DocEntry}>
													<td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
														{item.DocEntry}
													</td>
													<td className="px-6 py-4">
														{+item.DocTotal - item.PaidToDate}{' '}
														{item.DocCurrency}
													</td>
													<td className="px-6 py-4">
														<input
															className={
																'border-[1px] border-[#DFE2E9"] p-2 rounded-md'
															}
															type="number"
															onChange={(event) =>
																handlePayingSum(event, item.DocEntry)
															}
														/>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
							<div className={'flex gap-6 flex-wrap mb-4'}>
								<div className={'min-w-[150px]'}>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Валюта
									</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										onChange={handleCurrencyChange}
									>
										<option value="UZS">UZS</option>
										<option value="USD">USD</option>
									</select>
								</div>
								<div className={'min-w-[250px]'}>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Счот
									</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										onChange={handleClickCashAccount}
									>
										{cashAccountsData.map((item) => {
											return (
												<option key={item?.Code} value={item?.Code}>
													{item.Name} - {item?.Code}
												</option>
											)
										})}
									</select>
								</div>
							</div>
							<div className={'w-full md:w-[500px] mb-4'}>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Комментарий
								</p>
								<textarea
									className={'border-[1px] border-[#DFE2E9"] w-full h-[150px]'}
									onChange={handleRemarks}
								></textarea>
							</div>
							<Button isLoading={loading} onClick={handleOpenModal}>
								Submit
							</Button>
						</div>
					)}
				</div>
			</PaySupplier>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>

			<ConfirmModal
				show={showModal}
				data={confirmationMessage}
				onConfirm={() => {
					setShowModal(false)
					submitForm()
				}}
				onCancel={() => {
					setShowModal(false)
				}}
			/>
		</Layout>
	)
}

export default PaymentSupplierPage
