import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
	SuccessModal,
	ErrorModal,
	PaymentModal,
	RefundModal,
} from '../../components/Modal'
import numberWithSpaces from '../../helpers/numberWithSpaces'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import NoDollar from '../CreateProducts/NoDollar'
import Layout from '../../components/Layout'
import Button from '../../components/Button'
import ViewItemStyle from './ViewItemStyle'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import api, { customFuncsApi, logsUrl } from '../../api'
import { roundCurrency } from '../../utils'
import moment from 'moment'
import customMainApi from '../../api'
import { isEmpty } from 'lodash/lang'
import { AiOutlineSend } from 'react-icons/ai'
import { COMPANY_NAME } from '../../config'

const PurchaseInvoiceView = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const successModalRef = useRef()
	const errorRef = useRef()
	const noDollarRef = useRef()
	const paymentRef = useRef()

	const { getMe } = useSelector((state) => state.main)
	const data = get(location, 'state', {})
	const DocEntry = get(location, 'state.DocEntry', 0)
	// const DocEntry = get(location, 'state.DocEntry', 0)

	const getNoDollarRef = useCallback((ref) => {
		noDollarRef.current = ref
	}, [])

	const [tableData, setTableData] = useState([])
	const [tableData2, setTableData2] = useState([])
	const [deviceInfoData, setDeviceInfoData] = useState([])
	const [downloadData, setDownloadData] = useState({ state: location.state })
	const [info1, setInfo1] = useState('')
	const [info2, setInfo2] = useState('')
	const InstlmntID = get(location, 'state.data.InstlmntID', 0)
	const [comment, setComment] = useState('')
	const [sumPay, setSumPay] = useState(0)
	const [paymentSum, setPaymentSum] = useState(0)
	const [dollar, setDollar] = useState(1)
	const [invoice, setInvoice] = useState({})
	const [dollarLoading, setDollarLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isLoading2, setIsLoading2] = useState(false)

	useEffect(() => {
		getInformationOfItem()
		getInfoOFDevice()
	}, [])

	const getInformationOfItem = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInstallments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=50',
				},
			})
			.then((res) => {
				const resData = res.data.value || []
				setTableData2(resData)
				setTableData(resData)
				setIsLoading(false)
				let a = JSON.parse(JSON.stringify(resData))
				let allSum = 0
				let paymentSum = 0
				for (let i = 0; i < a.length; i++) {
					allSum = allSum + a[i].PaidToDate
					paymentSum = paymentSum + a[i].InsTotal
				}
				setSumPay(allSum)
				setPaymentSum(paymentSum)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getUSD = () => {
		setDollarLoading(true)
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: 'UZS',
				Date: moment(),
			})
			.then((res) => {
				setDollar(JSON.parse(res.data))
			})
			.catch((err) => {
				setDollar('error')
			})
			.finally(() => setDollarLoading(false))
	}

	useEffect(() => {
		getUSD()
	}, [])

	const getInfoOFDevice = () => {
		setIsLoading2(true)

		api
			.get(`Invoices(${DocEntry})`)
			.then((res) => {
				setComment(get(JSON.parse(res.data), 'Comments', ''))
				const resData = get(JSON.parse(res.data), 'DocumentLines[0]', [])
				setDeviceInfoData(resData)
				setIsLoading2(false)
				setInvoice(JSON.parse(res.data))
				setDownloadData(JSON.parse(res.data))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getItems = (code) => {
		api
			.get(`Items('${code}')`)
			.then((res) => {
				setInfo1(
					get(JSON.parse(res.data), 'U_Condition', 'Состояние не найден'),
				)
				setInfo2(get(JSON.parse(res.data), 'U_Color', 'Цвет не найден'))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const createPayment = ({
		DocDate,
		CurrencyRate,
		AmountUsd,
		AmountUzs,
		AmountPayme,
		NumberCard,
		Terminal,
		UsdCard,
	}) => {
		setIsLoading(true)
		const payments = []
		if (AmountUsd) {
			payments.push({
				usdSum: AmountUsd,
				account: UsdCard || getMe.U_CashAccount,
				currency: 'USD',
				uzsSum: null,
			})
		}
		if (AmountUzs) {
			payments.push({
				usdSum: AmountUzs / CurrencyRate,
				account: getMe.U_CashUzsAccount,
				currency: 'UZS',
				uzsSum: AmountUzs,
			})
		}
		if (AmountPayme) {
			payments.push({
				usdSum: AmountPayme / CurrencyRate,
				account: NumberCard || getMe.U_CardAccount,
				currency: 'UZS',
				uzsSum: AmountPayme,
			})
		}
		if (Terminal) {
			payments.push({
				usdSum: Terminal / CurrencyRate,
				account: getMe.U_TerminalAccount,
				currency: 'UZS',
				uzsSum: Terminal,
			})
		}
		customFuncsApi
			.post('addIncomingPayments', {
				DocDate,
				DocEntry: DocEntry,
				CardCode: get(data, 'CardCode', null),
				DocRate: CurrencyRate,
				payments: payments,
			})
			.then((err) => {
				try {
					logsUrl.post('apis', {
						company_name: COMPANY_NAME,
						method: 'post',
						path: `/addIncomingPayments`,
						description: "sotuvga to'lov",
						username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
						body: JSON.stringify({
							DocDate,
							DocEntry: DocEntry,
							CardCode: get(data, 'CardCode', null),
							DocRate: CurrencyRate,
							payments: payments,
						}),
					})
				} catch (err) {
					errorRef.current?.open(
						'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
					)
				}
				paymentRef.current?.close()
				successModalRef.current?.open('Платеж успешно произведен!')
				getInformationOfItem()
			})
			.catch((err) => {
				paymentRef.current?.close()
				errorRef.current?.open(JSON.stringify(err.response.data))
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const cancel = () => {
		navigate(-1)
	}

	const sendComment = () => {
		setComment('')
		const date = moment().tz('Etc/GMT-5').format('YYYY-MM-DDTHH:mm:ssZ')

		const body = {
			U_Employee: get(getMe, 'EmployeeID', ''),
			U_CreateDate: date,
			U_Invoice: get(data, 'DocEntry', ''),
			U_InstlmntID: InstlmntID,
			U_Text: comment,
			U_CreateTime: date,
		}
		api
			.post('U_COMMENTS', body)
			.then((res) => {})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	return (
		<Layout>
			<ViewItemStyle>
				<div className="container">
					<div className="grid grid-cols-1 w-full xl:grid-cols-3 gap-6">
						<div className="grid col-span-2 sm:grid-cols-2 gap-6 mb-8 h-fit">
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>ФИО</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'CardName', 'Покупатель')}`}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Продукт
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'Dscription', 'Товар')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Кому принадлежит телефон
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'U_Owner', '-')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Гарант
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(data, 'U_Guarantor', '-')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>IMEI</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(
										deviceInfoData,
										'SerialNumbers[0].InternalSerialNumber',
										0,
									)}`}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Серийный номер
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									// value={`${get(
									//   deviceInfoData,
									//   'SerialNumbers[0].InternalSerialNumber',
									//   0,
									// )}`}
									value={`${get(deviceInfoData, 'SerialNum', 0)}`}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Цвет</p>
								<input
									// type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={info2}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Состояние
								</p>
								<input
									// type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={info1}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Текущая цена
								</p>
								<input
									type="number"
									placeholder="Текущая цена"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(
										deviceInfoData,
										'GrossBuyPrice',
										'Текущая цена не найден',
									)}`}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Цена</p>
								<input
									type="number"
									placeholder="Цена"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(invoice, 'DocTotal', 'Цена не найден')}`}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									ICloud
								</p>
								<input
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={`${get(invoice, 'U_Icloud', '-')}`}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Комментария
								</p>
								<textarea
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									rows="1"
									disabled={true}
									value={comment}
								/>
							</div>
						</div>

						<divx>
							<div className="flex flex-col gap-2 sm:gap-6">
								<p>
									<span className="font-bold text-zinc-900">Курс:</span>{' '}
									{dollar === 'error'
										? 'Курс не загружен. Пожалуйста, введите курс'
										: numberWithSpaces(dollar) + ' ' + 'UZS'}
								</p>
								<p>
									<span className="font-bold text-zinc-900">Долг:</span>{' '}
									<span className="debt">
										-{(paymentSum - sumPay).toFixed(2)}
									</span>{' '}
									USD
								</p>
							</div>
							<div className="center py-5 flex gap-3 flex-wrap">
								<Button
									btnStyle={{ backgroundColor: '#243AB5' }}
									onClick={() => paymentRef.current?.open()}
									isLoading={isLoading || isLoading2 || dollarLoading}
									hoverBtnStyle={{ backgroundColor: '#243AB5' }}
								>
									Оплатить
								</Button>
							</div>

							<div className="overflow-y-auto mb-8">
								<table className="w-full text-sm text-left rtl:text-right text-black ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												N
											</th>
											<th scope="col" className="px-6 py-4">
												Дата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
										</tr>
									</thead>
									<tbody>
										{tableData.map((v, i) => {
											let a = v.DueDate.split('')
											a.splice(4, 0, '-')
											a.splice(7, 0, '-')
											a.join()
											return (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{i}
													</td>
													<td className="px-6 py-4 whitespace-nowrap">{a}</td>
													<td className="px-6 py-4">
														{Number(v.InsTotal).toFixed(2)}
													</td>
													<td className="px-6 py-4">
														{roundCurrency(v.PaidToDate)}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</divx>
					</div>

					<div className="bottomCard justify-end mt-8">
						<Button btnStyle={{ backgroundColor: 'red' }} onClick={cancel}>
							Назад
						</Button>

						<Button
							btnStyle={{ marginLeft: 20, width: 200 }}
							onClick={() => {
								if (window.location.hostname.toLowerCase().includes('istyle')) {
									navigate('/ru_download', {
										state: { data: downloadData, user: data, tableData },
									})
								} else {
									navigate('/download', {
										state: { data: downloadData },
									})
								}
							}}
						>
							Загрузить договор(Ру)
						</Button>
						{window.location.hostname.toLowerCase().includes('istyle') && (
							<Button
								btnStyle={{ marginLeft: 20, width: 225 }}
								onClick={() =>
									navigate('/uz_download', {
										state: { data: downloadData, user: data, tableData },
									})
								}
							>
								Шартномани юклаш
							</Button>
						)}
					</div>
				</div>
			</ViewItemStyle>
			<>
				{dollarLoading ? null : (
					<PaymentModal
						getRef={(r) => (paymentRef.current = r)}
						onConfirm={(form) => createPayment(form)}
						onClose={() => paymentRef.current?.close()}
						loading={isLoading}
						currencyRate={dollar}
					/>
				)}

				<NoDollar getRef={getNoDollarRef} />
				<SuccessModal
					getRef={(r) => (successModalRef.current = r)}
					onConfirm={() => successModalRef.current?.close()}
				/>
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
			</>
		</Layout>
	)
}

export default PurchaseInvoiceView
