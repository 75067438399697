import moment from 'moment/moment'
import Refund from '../../components/Modal/refund/Refund'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import api, { customFuncsApi, logsUrl } from '../../api'
import Layout from '../../components/Layout'
import { get } from 'lodash'
import { ErrorModal, SuccessModal } from '../../components/Modal'
import { COMPANY_NAME } from '../../config'
import ConfirmModal from '../../components/ComfirmModal'

const Convert = () => {
	const { getMe } = useSelector((state) => state.main)
	const [form, setForm] = useState({
		DocDate: moment().format('YYYY-MM-DD'),
		Type: 'UsdToUzs',
		CashSumUzs: '',
		CashSumUsd: '',
	})
	const successModalRef = useRef()
	const errorRef = useRef()

	const [stateCurrencyRate, setStateCurrencyRate] = useState(1)
	const [currencyLoading, setCurrencyLoading] = useState(false)
	const [schot1, setSchot1] = useState()
	const [schot2, setSchot2] = useState()
	const [refundLoading, setRefundLoading] = useState(false)
	const [accountsData, setAccountsData] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')

	const handleOpenModal = () => {
		setConfirmationMessage([
			{
				name: 'Дата:',
				value: form.DocDate,
			},
			{
				name: 'Курс:',
				value: stateCurrencyRate,
			},
			{
				name: 'Наличная сумма uzs:',
				value: form.CashSumUzs,
			},
			{
				name: 'Счет расхода:',
				value: form.Type === 'UsdToUzs' ? schot2 : schot1,
			},
			{
				name: 'Наличная сумма usd:',
				value: form.CashSumUsd,
			},
			{
				name: 'Счет расхода:',
				value: form.Type === 'UsdToUzs' ? schot1 : schot2,
			},
		])
		setShowModal(true)
	}

	const getUSD = () => {
		setCurrencyLoading(true)
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: 'UZS',
				Date: moment(),
			})
			.then((res) => {
				setStateCurrencyRate(JSON.parse(res.data))
			})
			.catch((err) => {
				errorRef.current?.open(JSON.stringify(err))
			})
			.finally(() => {
				setCurrencyLoading(false)
			})
	}

	useEffect(() => {
		getUSD()
	}, [])

	const handleTypeChange = (e) => {
		const newType = e.target.value
		setForm((prevState) => {
			if (newType === 'UsdToUzs') {
				const usdValue =
					prevState.CashSumUsd ||
					(prevState.CashSumUzs / stateCurrencyRate).toFixed(2)
				return {
					...prevState,
					Type: newType,
					CashSumUsd: usdValue,
					CashSumUzs: (usdValue * stateCurrencyRate).toFixed(2),
				}
			} else {
				const uzsValue =
					prevState.CashSumUzs ||
					(prevState.CashSumUsd * stateCurrencyRate).toFixed(2)
				return {
					...prevState,
					Type: newType,
					CashSumUzs: uzsValue,
					CashSumUsd: (uzsValue / stateCurrencyRate).toFixed(2),
				}
			}
		})
	}

	const handleSumChange = (e) => {
		const value = e.target.value
		setForm((prevState) => {
			if (prevState.Type === 'UsdToUzs') {
				return {
					...prevState,
					CashSumUsd: value,
					CashSumUzs: (value * stateCurrencyRate).toFixed(2),
				}
			} else {
				return {
					...prevState,
					CashSumUzs: value,
					CashSumUsd: (value / stateCurrencyRate).toFixed(2),
				}
			}
		})
	}

	const handleRefund = ({ DocDate, Type, CashSumUzs, CashSumUsd }) => {
		setRefundLoading(true)
		customFuncsApi
			.post(`addMoneyChange`, {
				DocDate: DocDate,
				Type: Type,
				[Type === 'UsdToUzs' ? 'CashUsdAccount' : 'CashUzsAccount']: schot1,
				[Type === 'UsdToUzs' ? 'CashUzsAccount' : 'CashUsdAccount']: schot2,
				CashSumUzs: +CashSumUzs,
				CashSumUsd: +CashSumUsd,
			})
			.then(() => {
				successModalRef.current?.open('Платеж успешно произведен!')
				logsUrl.post('apis', {
					company_name: COMPANY_NAME,
					method: 'post',
					path: customFuncsApi + `/addMoneyChange`,
					description: 'konvertatsiya',
					username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
					body: JSON.stringify({
						DocDate: DocDate,
						Type: Type,
						[Type === 'UsdToUzs' ? 'CashUsdAccount' : 'CashUzsAccount']: schot1,
						[Type === 'UsdToUzs' ? 'CashUzsAccount' : 'CashUsdAccount']: schot2,
						CashSumUzs: +CashSumUzs,
						CashSumUsd: +CashSumUsd,
					}),
				})
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setRefundLoading(false)
			})
	}

	useEffect(() => {
		api
			.get(
				"ChartOfAccounts?$filter=startswith(Code, '57') and AcctCurrency eq 'UZS' and ExternalCode eq '1'&$select=Code,Name,AcctCurrency",
				{
					headers: {
						Prefer: 'odata.maxpagesize=10000',
					},
				},
			)
			.then((res) => {
				setAccountsData(JSON.parse(res.data).value)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}, [])

	return (
		<Layout>
			<Refund>
				<div className={'container mx-auto pt-10'}>
					<div className="">
						<h2 className={'text-center mb-5'}>{'Конвертатция'}</h2>
						<div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-10">
							<input
								type="date"
								placeholder="Date"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={form.DocDate}
								onChange={(v) =>
									setForm((prevState) => ({
										...prevState,
										DocDate: v.target.value,
									}))
								}
							/>
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={stateCurrencyRate}
								onChange={(v) => setStateCurrencyRate(v.target.value)}
							/>
							<input
								type="text"
								placeholder="Cумма"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={
									form.Type === 'UsdToUzs' ? form.CashSumUsd : form.CashSumUzs
								}
								onChange={handleSumChange}
							/>
							<div className={'flex gap-5'}>
								<select
									className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md'}
									value={form.Type}
									onChange={handleTypeChange}
								>
									<option value="UsdToUzs">{'USD'}</option>
									<option value="UzsToUsd">{'UZS'}</option>
								</select>
								<div className="relative">
									<input
										type="text"
										list="productName"
										placeholder={'Номер карта '}
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1'
										}
										value={form.numberCard}
										onChange={(v) => setSchot1(v.target.value)}
									/>
									<datalist id="productName">
										{accountsData.map((v, i) => (
											<option
												key={i}
												label={`${get(v, 'Name', '')} , ${get(v, 'Code', '')}`}
												value={get(v, 'Code', '')}
											/>
										))}
									</datalist>
								</div>
							</div>
							<input
								type="text"
								placeholder="Другая валюта"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								disabled
								value={
									form.Type === 'UsdToUzs'
										? form.CashSumUzs + ' UZS'
										: form.CashSumUsd + ' USD'
								}
							/>
							<div className="relative">
								<input
									type="text"
									list="productName"
									placeholder={'Номер карта '}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1'
									}
									value={form.numberCard}
									onChange={(v) => setSchot2(v.target.value)}
								/>
								<datalist id="productName">
									{accountsData.map((v, i) => (
										<option
											key={i}
											label={`${get(v, 'Name', '')} , ${get(v, 'Code', '')}`}
											value={get(v, 'Code', '')}
										/>
									))}
								</datalist>
							</div>
						</div>
						<div className="centerCard">
							<button
								className="btnY disabled:cursor-not-allowed disabled:bg-gray-300 hover:bg-green-600 hover:text-white border-[4px] border-green-600 disabled:border-gray-300 disabled:border-gray-300"
								disabled={
									form.DocDate === '' ||
									form.Type === '' ||
									form.CashSumUzs === '' ||
									form.CashSumUsd === '' ||
									currencyLoading ||
									refundLoading
								}
								onClick={() => {
									handleOpenModal()
								}}
							>
								{'Да'}
							</button>
						</div>
					</div>
				</div>
				<SuccessModal getRef={(r) => (successModalRef.current = r)} />
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
			</Refund>
			<ConfirmModal
				show={showModal}
				data={confirmationMessage}
				onConfirm={() => {
					setShowModal(false)
					handleRefund(form)
				}}
				onCancel={() => {
					setShowModal(false)
				}}
			/>
		</Layout>
	)
}

export default Convert

// import React from 'react'
// import Layout from '../../components/Layout'
//
// const MyComponent = () => {
// 	return (
// 		<Layout>
// 			<div className={'p-12'}>Not available</div>
// 		</Layout>
// 	)
// }
//
// export default MyComponent
