import React, { useEffect, useRef, useState } from 'react'
import CreateProductsStyle from './CreateProductsStyle'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi, logsUrl } from '../../api'
import { get } from 'lodash'
import { BsSearch } from 'react-icons/bs'
import time from '../../helpers/time'
import {
	ErrorModal,
	SuccessModal,
	WarningModal,
	CreateUser,
} from '../../components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import ChangeDate from './ChangeDate'
import { main } from '../../store/slices'
import Layout from '../../components/Layout'
import moment from 'moment'
import { roundCurrency } from '../../utils'
import CreateProductModal from '../../components/Modal/CreateProduct'
import warningModal from '../../components/Modal/WarningModal'
import { COMPANY_NAME } from '../../config'

const CreateProductsPartner = () => {
	const { setDate } = main.actions
	const { getMe } = useSelector((state) => state.main)

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const changeDateRef = useRef()
	const errorRef = useRef()
	const infoNotEndedRef = useRef()
	const successModalRef = useRef()
	const succesCreateUserRef = useRef()
	const createUserRef = useRef()
	const createProductRef = useRef()

	const [customerData, setCustomerData] = useState([])
	const [productNameData, setProductNameData] = useState([])
	const [seriaNumberData, setSeriaNumberData] = useState([])
	const [tableData, setTableData] = useState([])
	const [simpleMonthData, setsimpleMonthData] = useState([])
	const [vipMonthData, setVipMonthData] = useState([])

	const [cloud, setCloud] = useState('')
	const [comment, setComment] = useState('')
	const [price, setPrice] = useState('')
	const [color, setColor] = useState('')
	const [month, setMonth] = useState('')
	const [monthB2B, setMonthB2B] = useState('')
	const [customer, setCustomer] = useState('')
	const [condition, setCondition] = useState('')
	const [productName, setProductName] = useState('')
	const [seriaNumber, setSeriaNumber] = useState('')
	const [currentPrice, setCurrentPrice] = useState(0)
	const [userCardCode, setUserCardCode] = useState('')
	const [seriaNumberMain, setSeriaNumberMain] = useState('')
	const [systemSerialNumber, setSystemSerialNumber] = useState('')
	const [minusedMoney, setMinusedMoney] = useState(0)
	const [productCode, setProductCode] = useState(undefined)
	const [dateIndex, setDateIndex] = useState(0)
	const [viewData, setViewData] = useState({})
	const [isCash, setIsCash] = useState(false)
	const [firstPayingMoney, setFirstPayingMoney] = useState(0)
	const [isCalculated, setIsCalculated] = useState(false)
	const [isMainLoading, setIsMainLoading] = useState(false)
	const [createUserLoading, setCreateUserLoading] = useState(false)

	useEffect(() => {
		getSimpleMonth()
		getVIPMonth()
	}, [])

	const getSimpleMonth = () => {
		api
			.get('U_INSTCONFBASIC')
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setsimpleMonthData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
	}

	const viewItem = (v) => {
		navigate('/viewItem', { state: v })
	}

	const getVIPMonth = () => {
		api
			.get('U_INSTCONFVIP')
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setVipMonthData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
	}

	const searchUser = (a = '') => {
		setCustomer(a)
		api
			.get(
				`BusinessPartners?$select=CardCode,CardName,SalesPersonCode&$filter=CardType eq 'cCustomer' and Frozen eq 'tNO' and (contains(CardName, '${a}') or contains(U_PS, '${a}') or contains(Phone1, '${a}'))`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=100000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setCustomerData(resData)
				if (a.includes(' , ')) {
					setUserCardCode(a.split(' , ')[1])
				}
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
	}

	const searchProduct = (a = '') => {
		setProductName(a)
		api
			.get(
				`Items?$select=ItemCode,ItemName,U_Color,U_Condition&$filter=contains(ItemName,'${a.split(' , ')[0]}')`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=10000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setProductNameData(resData)
				getSameSerialNumnber(a.split(', ')[1])
				setProductCode(a.split(', ')[1])
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
	}

	const getSameSerialNumnber = (num) => {
		api
			.get(`SQLQueries('getItem')/List?itemCode='${num}'`, {
				headers: {
					Prefer: 'odata.maxpagesize=1000000',
				},
			})
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setSeriaNumberData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить серийный номер. Повторите попытку.',
					),
				)
			})
	}

	// const getOtherInformations = (v) => {
	//   const num = Number(v.split(') ')[0]) - 1
	//   setSeriaNumber(v)
	//   const code = v.split(') ')[1] || ''
	//   seriaNumberData.forEach((el, index) => {
	//     if (get(el, 'DistNumber', '') === code) {
	//       setSystemSerialNumber(get(el, 'SysNumber', ''))
	//     }
	//   })
	//   setSeriaNumberMain(v.split(') ')[1])
	//   const dataO = seriaNumberData[num]
	//
	//   setColor(get(dataO, 'U_Color', ''))
	//   setCondition(get(dataO, 'U_Condition', ''))
	//   setCurrentPrice(get(dataO, 'CostTotal', ''))
	// }

	const getOtherInformationsfromSerialNumber = () => {
		api
			.get(
				`SQLQueries('getItemsBySerialNumber')/List?serial='${seriaNumber}%25'`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=100000',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setProductNameData(resData)
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
	}

	const createProduct = (form) => {
		api
			.post('Items', {
				ItemCode: form.ItemName,
				ItemName: form.ItemName,
				U_Color: form.U_Color,
				U_Condition: form.U_Condition,
				ManageSerialNumbers: 'tYES',
				CostAccountingMethod: 'bis_SNB',
				IssueMethod: 'im_Manual',
			})
			.then((res) => {
				createProductRef.current?.close()
				succesCreateUserRef.current?.open('Продукт успешно создан')
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Продукт не был создан',
					),
				)
			})
	}
	const createUserF = (
		consumer,
		passport,
		male,
		address,
		phone,
		cardCode,
		workplace,
		typeCardCode,
	) => {
		setCreateUserLoading(true)
		console.log(typeCardCode)
		api
			.post(`BusinessPartners`, {
				[typeCardCode ? 'CardCode' : 'Series']: cardCode,
				CardName: consumer,
				CardType: 'cCustomer',
				U_Telephone: phone.length ? phone : undefined,
				U_Gender: male,
				U_PS: passport.length ? passport : undefined,
				Currency: '##',
				U_workplace: workplace.length ? workplace : undefined,
				U_address: address.length ? address : undefined,
			})
			.then(() => {
				try {
					logsUrl.post('apis', {
						company_name: COMPANY_NAME,
						method: 'post',
						path: '/BusinessPartners',
						description: 'sehrikda business partner yaratish',
						username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
						body: JSON.stringify({
							CardCode: cardCode,
							CardName: consumer,
							CardType: 'cCustomer',
							U_Telephone: phone.length ? phone : undefined,
							U_Gender: male,
							U_PS: passport.length ? passport : undefined,
							Currency: '##',
							U_workplace: workplace.length ? workplace : undefined,
							U_address: address.length ? address : undefined,
						}),
					})
				} catch (err) {
					errorRef.current?.open(
						'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
					)
				}
				createUserRef.current?.close()
				succesCreateUserRef.current?.open('Пользователь успешно создан')
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
			.finally(() => {
				setCreateUserLoading(false)
			})
	}

	const addTable = () => {
		if (
			customer.length > 0 &&
			productName.length > 0 &&
			seriaNumber.length > 0 &&
			(month.length > 0 || monthB2B.length > 0)
		) {
			let monthNumber = monthB2B.length > 0 ? monthB2B : month
			monthNumber = Number(monthNumber.split(' oy')[0]) + 1

			setTableData([])

			for (let i = 0; i < monthNumber; i++) {
				let nextMonth = moment().clone().add(i, 'months')
				setTableData((pr) => [
					...pr,
					{
						date: nextMonth.format('YYYY-MM-DD'),
						price: 0,
						price2: 0,
					},
				])
			}
			setIsCalculated(true)
		} else {
			infoNotEndedRef.current?.open()
		}
	}

	useEffect(() => {
		if (month.length > 0 || monthB2B.length > 0) {
			addTable()
		}
	}, [month, monthB2B])

	const ditributeMoney = (money) => {
		setFirstPayingMoney(money)
		let protsent = monthB2B.length > 0 ? monthB2B : month
		let splitProsent = protsent.split(' oy')

		let whichType = monthB2B.length > 0 ? vipMonthData : simpleMonthData
		protsent =
			1 +
			Number(
				whichType.find((item) => item.U_Months == splitProsent[0]).U_Percent,
			) /
				100

		let sameNumber = roundCurrency(
			(Number(currentPrice) - Number(money)) * protsent,
		)

		let monthMoney = roundCurrency(+sameNumber / (tableData.length - 1))

		setMinusedMoney(Number(money))
		let b = roundCurrency(+sameNumber + Number(money))
		setPrice(b)

		if (Number(money) > 0 && Number(money) < Number(price)) {
			for (let i = 0; i < tableData.length; i++) {
				if (i !== 0) {
					let aa = [...tableData]
					aa[i].price = monthMoney
					setTableData(aa)
				} else {
					let aa = [...tableData]
					aa[0].price = money
					setTableData(aa)
				}
			}
		}
	}

	//main create Product

	const checkU_WHSCode = () => {
		if (get(getMe, 'U_Warehouse', null) === null) {
			alert('Foydalanuvchiga ombor biriktirilmagan')
		} else {
			createOrder()
		}
	}

	const createOrder = () => {
		const nowDays = new Date()
		let whichType = monthB2B.length > 0 ? vipMonthData : simpleMonthData

		if (!isCash) {
			let monthNumber = monthB2B.length > 0 ? monthB2B : month || ''
			monthNumber = Number(monthNumber.split(' oy')[0])

			let protsent = monthB2B.length > 0 ? monthB2B : month || ''
			let splitProsent = protsent.split(' oy')

			protsent =
				1 +
				Number(
					whichType.find((item) => item.U_Months == splitProsent[0]).U_Percent,
				) /
					100
		}
		setIsMainLoading(true)

		const dataT = tableData.map((v) => {
			return {
				Total: v.price,
				DueDate: v.date,
			}
		})

		let body = {
			SupCardCode: get(getMe, 'U_Supplier', ''),
			CardCode: userCardCode,
			DocDate: time.to(nowDays, 'YYYY-MM-DD'),
			SalesPersonCode: get(getMe, 'SalesPersonCode', ''),
			U_Icloud: cloud,
			Comments: comment,
			DocumentLines: [
				{
					ItemCode: productCode,
					SerialNumbers: [
						{
							InternalSerialNumber: seriaNumber,
						},
					],
					Quantity: 1,
					purchasePrice: currentPrice,
					UnitPrice: price,
					WarehouseCode: get(getMe, 'U_Warehouse', ''),
				},
			],
		}
		if (!isCash) Object.assign(body, { DocumentInstallments: dataT })
		customFuncsApi
			.post(`addPartnerInvoiceAndPurchase`, body)
			.then((res) => {
				setViewData({
					...res.data.Invoice,
					CardName: get(res.data.Invoice, 'CardName', null),
					CardCode: get(res.data.Invoice, 'CardCode', null),
					DocEntry: get(res.data.PurchaseInvoice, 'DocEntry', null),
					InvoiceDocEntry: get(res.data.Invoice, 'DocEntry', null),
					DocumentLines: {
						ItemDescription: productName,
					},
					Dscription: productName,
				})
				res.data.Invoice !== null &&
				!res.data.Invoice.error &&
				!res.data.PurchaseInvoice.error
					? successModalRef.current?.open('Заказ успешно создан')
					: errorRef.current?.open(JSON.stringify(res.data.PurchaseInvoice.error.message))
				historyInvoice(body)
			})
			.catch((err) => {
				errorRef.current?.open(JSON.stringify(err.response))
			})
			.finally(() => {
				setIsMainLoading(false)
			})
	}

	const historyInvoice = (body) => {
		try {
			logsUrl.post('apis', {
				company_name: COMPANY_NAME,
				method: 'post',
				path: `/addPartnerInvoiceAndPurchase`,
				description: 'sherikda invoice yaratish',
				username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
				body: JSON.stringify(body),
			})
		} catch (err) {
			errorRef.current?.open(
				'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
			)
		}
	}

	const changeMainPrice = (v) => {
		let mainPrice = Number(v)
		setPrice(v)

		let dataT = mainPrice
		let A = (dataT - minusedMoney) / (Number(tableData.length) - 1)

		for (let i = 0; i < tableData.length; i++) {
			if (i !== 0) {
				let aa = [...tableData]
				aa[i].price = A
				setTableData(aa)
			} else {
				let aa = [...tableData]
				aa[0].price = minusedMoney
				setTableData(aa)
			}
		}
	}

	const changeDate = (v) => {
		let exampleDate = [...tableData]
		let day = v.slice(8, 10)

		for (let i = 0; i < exampleDate.length; i++) {
			let nextMonth = moment().clone().add(i, 'months').format('YYYY-MM-DD')
			let selectedDay = nextMonth.slice(0, 8) + day

			if (dateIndex <= i) {
				exampleDate[i].date = selectedDay
			}
		}
		setTableData(exampleDate)
		changeDateRef.current?.close()
	}

	const openChangeDate = (date, i) => {
		dispatch(setDate(date))
		changeDateRef.current?.open()
		setDateIndex(i)
	}

	const handleChangeValues = (v) => {
		let aa = [...tableData]
		let total = 0
		for (let i = 1; i < tableData.length; i++) {
			aa[i].price = v
			total += Number(v)
		}
		total += +firstPayingMoney
		setTableData(aa)
		setPrice(total)
	}

	return (
		<Layout>
			<CreateProductsStyle>
				<div className="container">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-7 lg:grid-cols-3 items-start">
						<div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:col-span-2">
							<div>
								<div className="mb-1">
									<label
										htmlFor="clientInput"
										className={'font-medium text-zinc-600 text-sm mb-1'}
									>
										Клиент
									</label>
								</div>
								<div className="flex gap-2">
									<input
										type="text"
										list="client"
										id={'clientInput'}
										autocomplete="off"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										defaultValue={customer}
										onChange={(v) => searchUser(v.target.value)}
									/>
									<datalist id="client">
										{customerData.map((v, i) => (
											<option
												key={i}
												value={`${get(v, 'CardName', '')} , ${get(
													v,
													'CardCode',
													'',
												)}`}
											/>
										))}
									</datalist>
									<button
										className="bg-[#0A4D68] text-white px-4 rounded-md"
										onClick={() => createUserRef.current?.open()}
									>
										+
									</button>
								</div>
							</div>
							<div>
								<div className="mb-1">
									<label
										htmlFor="productNameInput"
										className={'font-medium text-zinc-600 text-sm mb-1'}
									>
										Товар
									</label>
								</div>
								<div className="relative">
									<input
										type="text"
										list="productName"
										autocomplete="off"
										id={'productNameInput'}
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										defaultValue={productName}
										onChange={(v) => searchProduct(v.target.value)}
									/>
									<datalist id="productName">
										{productNameData.map((v, i) => (
											<option
												key={i}
												value={`${get(v, 'ItemName', '')} , ${get(
													v,
													'ItemCode',
													'',
												)}`}
											/>
										))}
									</datalist>
								</div>
							</div>
							<div>
								<div className="mb-1">
									<label
										htmlFor="seriaNumber"
										className={'font-medium text-zinc-600 text-sm mb-1'}
									>
										Серийный номер
									</label>
								</div>
								<div className="flex gap-2">
									<input
										type="text"
										list="seriaNumber"
										id={'seriaNumber'}
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										defaultValue={seriaNumber}
										onChange={(v) => setSeriaNumber(v.target.value)}
									/>
									{/*<button*/}
									{/*  className="bg-[#0A4D68] text-white px-4 rounded-md"*/}
									{/*  onClick={getOtherInformationsfromSerialNumber}*/}
									{/*>*/}
									{/*  <BsSearch />*/}
									{/*</button>*/}
								</div>
							</div>
							<div>
								<div className="mb-1">
									<label
										htmlFor="color"
										className={'font-medium text-zinc-600 text-sm mb-1'}
									>
										Цвет
									</label>
								</div>
								<input
									type="text"
									id={'color'}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={
										productNameData.find(
											(item) => item.ItemCode === productCode,
										)?.U_Color
									}
									// defaultValue={}
									// onChange={(v) => setColor(v.target.value)}
									disabled={true}
								/>
							</div>
							<div>
								<div className="mb-1">
									<label
										htmlFor="condition"
										className={'font-medium text-zinc-600 text-sm mb-1'}
									>
										Состояние
									</label>
								</div>
								<input
									type="text"
									id={'condition'}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={
										productNameData.find(
											(item) => item.ItemCode === productCode,
										)?.U_Condition
									}
									defaultValue={condition}
									disabled={true}
								/>
							</div>

							<div>
								<div className="mb-1">
									<label
										htmlFor="currentPrice"
										className={'font-medium text-zinc-600 text-sm mb-1'}
									>
										Текущая цена
									</label>
								</div>
								<input
									id="currentPrice"
									type="number"
									placeholder=""
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={currentPrice}
									onChange={(e) => setCurrentPrice(e.target.value)}
								/>
							</div>
							<div className="flex  gap-2 items-end">
								<div>
									<div className="mb-1">
										<label
											htmlFor="monthInput"
											className={'font-medium text-zinc-600 text-sm mb-1'}
										>
											Месяц
										</label>
									</div>
									<input
										type="text"
										list="month"
										id={'monthInput'}
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										value={month}
										onChange={(v) => {
											setMonth(v.target.value)
											setMonthB2B('')
											setIsCash(false)
										}}
										autocomplete="off"
									/>
									<datalist id="month">
										{simpleMonthData.map((v, i) => {
											return <option key={i} value={`${v.U_Months} oy`} />
										})}
									</datalist>
								</div>
								<div>
									<div className="mb-1">
										<label
											htmlFor="monthB2BInput"
											className={'font-medium text-zinc-600 text-sm mb-1'}
										>
											Месяц B2B
										</label>
									</div>

									<input
										type="text"
										list="monthB2B"
										id={'monthB2BInput'}
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										value={monthB2B}
										onChange={(v) => {
											setMonthB2B(v.target.value)
											setMonth('')
											setIsCash(false)
										}}
										autocomplete="off"
									/>
									<datalist id="monthB2B">
										{vipMonthData.map((v, i) => {
											return <option key={i} value={`${v.U_Months} oy`} />
										})}
									</datalist>
								</div>
								<input
									type="button"
									className="px-2 rounded-md py-2.5"
									style={{
										backgroundColor: isCash ? '#0A4D68' : '#b4b4b4',
										color: 'white',
									}}
									value={'Наличные'}
									onClick={() => {
										setIsCash(true)
										setMonth('')
										setMonthB2B('')
										setMonth('')
										setPrice('')
										setMonthB2B('')
									}}
								/>
							</div>

							<div>
								<div className="mb-1">
									<label
										htmlFor="price"
										className={'font-medium text-zinc-600 text-sm mb-1'}
									>
										Цена
									</label>
								</div>
								<input
									type="number"
									id={'price'}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={price}
									onChange={(v) => changeMainPrice(v.target.value)}
								/>
							</div>
							<textarea
								placeholder="Comment"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full textarea'
								}
								value={comment}
								onChange={(v) => setComment(v.target.value)}
							/>
							<input
								placeholder="ICloud"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={cloud}
								onChange={(v) => setCloud(v.target.value)}
							/>
						</div>

						{isCash ? null : (
							<div>
								<div className="center mb-4">
									<Button onClick={addTable}>Расчет</Button>
								</div>

								{isCalculated ? (
									<div className="overflow-y-auto mb-8">
										<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
											<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
												<tr>
													<th scope="col" className="px-6 py-4">
														N
													</th>
													<th scope="col" className="px-6 py-4">
														Дата
													</th>
													<th scope="col" className="px-6 py-4">
														Оплата
													</th>
													<th scope="col" className="px-6 py-4">
														Оплаченный
													</th>
												</tr>
											</thead>
											<tbody>
												{tableData.map((v, i) => {
													return (
														<tr
															className="bg-white border-b  hover:bg-gray-50 "
															key={i}
														>
															<td className="px-6 py-4 font-medium text-gray-900 ">
																{i}
															</td>
															<td
																onClick={() => openChangeDate(v.date, i)}
																className="px-6 py-4 whitespace-nowrap"
															>
																{v.date}
															</td>
															<td className="px-6 py-4">
																{i === 0 && (
																	<input
																		type="number"
																		className={
																			'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
																		}
																		onChange={(v) =>
																			ditributeMoney(v.target.value)
																		}
																	/>
																)}
																{i === 1 && (
																	<input
																		type="number"
																		className={
																			'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
																		}
																		value={v.price}
																		onChange={(v) =>
																			handleChangeValues(v.target.value)
																		}
																	/>
																)}
																{i !== 0 && i !== 1 && Number(v.price)}
															</td>
															<td className="px-6 py-4">{v.price2}</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								) : null}
							</div>
						)}
					</div>

					<div className="flex gap-3 mt-8 justify-end w-full">
						<Button
							btnStyle={{ backgroundColor: 'red' }}
							onClick={() => navigate('/all-products-partner')}
						>
							Назад
						</Button>
						<Button onClick={checkU_WHSCode} isLoading={isMainLoading}>
							Создать
						</Button>
					</div>
				</div>
			</CreateProductsStyle>
			<>
				<CreateProductModal
					getRef={(r) => (createProductRef.current = r)}
					onConfirm={(form) => createProduct(form)}
				/>
				<CreateUser
					isLoading={createUserLoading}
					getRef={(r) => (createUserRef.current = r)}
					onConfirm={(
						consumer,
						passport,
						male,
						address,
						phone,
						cardCode,
						workplace,
						typeCardCode,
					) =>
						createUserF(
							consumer,
							passport,
							male,
							address,
							phone,
							cardCode,
							workplace,
							typeCardCode,
						)
					}
				/>
				<ErrorModal
					getRef={(r) => (errorRef.current = r)}
					onConfirm={() => {
						setIsMainLoading(false)
					}}
				/>
				<SuccessModal
					getRef={(r) => {
						succesCreateUserRef.current = r
					}}
				/>
				<ChangeDate
					getRef={(r) => {
						changeDateRef.current = r
					}}
					onConfirm={(v) => changeDate(v)}
				/>

				<WarningModal
					getRef={(ref) => {
						infoNotEndedRef.current = ref
					}}
					onConfirm={() => navigate('/all-products-partner')}
				/>
				<SuccessModal
					getRef={(r) => {
						successModalRef.current = r
					}}
					onConfirm={() => navigate('/viewPartnerInvoice', { state: viewData })}
				/>
			</>
		</Layout>
	)
}

export default CreateProductsPartner
