import React from 'react'
import LayoutStyle from './LayoutStyle'
import DrawerMenu from '../DrawerMenu'

const Layout = ({ children }) => {
	return (
		<LayoutStyle>
			<DrawerMenu />
			{children}
		</LayoutStyle>
	)
}

export default Layout
