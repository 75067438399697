export const API_ROOT_TEST = 'https://istyle-backend-test.bis-pro.com/b1s/v2/'
export const CUSTOM_API_ROOT_TEST =
	'https://istyle-backend-test.bis-pro.com/api/'

export const API_ROOT_PROD = 'https://icomfort-backend-prod.bis-pro.com/b1s/v2/'
export const CUSTOM_API_ROOT_PROD =
	'https://icomfort-backend-prod.bis-pro.com/api/'

	
export const COMPANY_NAME = 'icomfort'
export const LOGS_BASE_URL_TEST = 'https://logs-backend-test.bis-pro.com/api'
export const LOGS_BASE_URL_PROD = 'https://logs-backend-prod.bis-pro.com/api'
