import React, { memo, useEffect, useRef, useState } from 'react'
import UpdateUserStyle from './UpdateUserStyle'
import Modal from 'react-modal'
import { get } from 'lodash'
import api, { logsUrl } from '../../../api'
import { ErrorModal } from '../../../components/Modal'
import { AiOutlineReload } from 'react-icons/ai'
import { COMPANY_NAME } from '../../../config'
import { useSelector } from 'react-redux'
import ConfirmModal from '../../../components/ComfirmModal'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
}

const UpdateUserModal = (props) => {
	const { getRef = () => {}, onUpdated = () => {}, onClose = () => {} } = props
	const { getMe } = useSelector((state) => state.main)
	const errorRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [consumer, setConsumer] = useState('')
	const [passport, setPassport] = useState('')
	const [phone, setPhone] = useState('')
	const [workplace, setWorkplace] = useState('')
	const [address, setAddress] = useState('')
	const [gender, setGender] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')

	const handleOpenModal = () => {
		setConfirmationMessage([
			{
				name: 'Код клиента:',
				value: cardCode,
			},
			{
				name: 'Клиент:',
				value: consumer,
			},
			{
				name: 'Паспорт:',
				value: passport,
			},
			{
				name: 'Адрес:',
				value: address,
			},
			{
				name: 'Телефон:',
				value: phone,
			},
			{
				name: 'Рабочее место:',
				value: workplace,
			},
			{
				name: 'Пол:',
				value: gender,
			},
		])
		setShowModal(true)
	}
	useEffect(() => {
		const ref = {
			open: (data) => {
				console.log(data)
				setIsOpenModal(true)
				setCardCode(
					get(data, 'CardCode', null) !== null ? get(data, 'CardCode', '') : '',
				)
				setConsumer(
					get(data, 'CardName', null) !== null ? get(data, 'CardName', '') : '',
				)
				setAddress(
					get(data, 'Address', null) !== null ? get(data, 'Address', '') : '',
				)
				setPassport(
					get(data, 'U_PS', null) !== null ? get(data, 'U_PS', '') : '',
				)
				setPhone(
					get(data, 'Phone1', null) === null
						? get(data, 'U_Telephone', '')
						: get(data, 'Phone1', ''),
				)
				setGender(
					get(data, 'U_Gender', null) !== null ? get(data, 'U_Gender', '') : '',
				)
				setWorkplace(
					get(data, 'U_workplace', null) !== null
						? get(data, 'U_workplace', '')
						: '',
				)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const submit = () => {
		setIsLoading(true)
		let url = `BusinessPartners('${cardCode}')`
		const body = {
			U_Telephone: phone,
			CardName: consumer,
			Address: address,
			U_PS: passport,
			U_Gender: gender,
			U_workplace: workplace,
		}
		api
			.patch(url, body)
			.then(() => {
				try {
					logsUrl.post('apis', {
						company_name: COMPANY_NAME,
						method: 'patch',
						path: `/BusinessPartners('${cardCode}')`,
						description: 'client update qilish',
						username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
						body: JSON.stringify(body),
					})
				} catch (err) {
					errorRef.current?.open(
						'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
					)
				}
				onUpdated()
				setIsOpenModal(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => setIsLoading(false))
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<UpdateUserStyle>
				<div className="card">
					<h2 className="text-zinc-900 font-bold mb-4 text-[20px]">
						{'Обновите информацию'}
					</h2>
					<div className="flex flex-col gap-4">
						<input
							type="text"
							placeholder="Клиент"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={consumer}
							onChange={(v) => setConsumer(v.target.value)}
						/>
						<input
							type="text"
							placeholder="Паспорт"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={passport}
							onChange={(v) => setPassport(v.target.value)}
						/>
						<input
							type="text"
							placeholder="Адрес"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={address}
							onChange={(v) => setAddress(v.target.value)}
						/>
						<input
							// type="number"
							placeholder="Телефон"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={phone}
							onChange={(v) => setPhone(v.target.value)}
						/>
						<input
							placeholder="Рабочее место"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={workplace}
							onChange={(v) => setWorkplace(v.target.value)}
						/>
					</div>
					<div className="flex gap-2 my-6">
						<div className="flex items-center gap-1">
							<input
								defaultChecked={gender === 'Male'}
								type="radio"
								id="Male"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								name="fav_language"
								value="Male"
								onChange={(v) => setGender(v.target.value)}
							/>
							<label htmlFor="Male">Мужчина</label>
						</div>
						<div className="flex items-center gap-1">
							<input
								defaultChecked={gender === 'Female'}
								type="radio"
								id="Female"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								name="fav_language"
								value="Female"
								onChange={(v) => setGender(v.target.value)}
							/>
							<label htmlFor="Female">Женщина</label>
						</div>
					</div>
					<div className="centerCard">
						<button className="btnN" onClick={() => setIsOpenModal(false)}>
							{'Нет'}
						</button>
						<button
							className="btnY"
							disabled={cardCode.length <= 0 || consumer.length <= 0}
							onClick={handleOpenModal}
						>
							{!isLoading ? 'Да' : <AiOutlineReload size={'20'} />}
						</button>
					</div>
				</div>
			</UpdateUserStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
			<ConfirmModal
				show={showModal}
				data={confirmationMessage}
				onConfirm={() => {
					setShowModal(false)
					submit()
				}}
				onCancel={() => {
					setShowModal(false)
				}}
			/>
		</Modal>
	)
}

export default memo(UpdateUserModal)
