import React, { memo, useEffect, useState } from 'react'
import Refund from './Refund'
import Modal from 'react-modal'
import { useSelector } from 'react-redux'
import moment from 'moment'
import ConfirmModal from '../../ComfirmModal'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '900px',
	},
}

const RefundModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	currencyRate,
	isLoading,
}) => {
	const { getMe } = useSelector((state) => state.main)
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [form, setForm] = useState({
		DocDate: moment().format('YYYY-MM-DD'),
		Type: 'UsdToUzs',
		CashSumUzs: '',
		CashSumUsd: '',
	})
	const [stateCurrencyRate, setStateCurrencyRate] = useState(currencyRate)
	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')

	useEffect(() => {
		const ref = {
			open: () => setIsOpenModal(true),
			close: () => setIsOpenModal(false),
			clear: () =>
				setForm({
					DocDate: moment().format('YYYY-MM-DD'),
					Type: 'UsdToUzs',
					CashSumUzs: '',
					CashSumUsd: '',
				}),
		}
		getRef(ref)
	}, [])

	const handleOpenModal = () => {
		setConfirmationMessage([
			{
				name: 'Дата:',
				value: form.DocDate,
			},
			{
				name: 'Наличная сумма uzs:',
				value: !!form.CashSumUzs ? form.CashSumUzs : 0,
			},
			{
				name: 'Наличная сумма usd:',
				value: !!form.CashSumUsd ? form.CashSumUsd : 0,
			},
			{
				name: 'Наличные uzc cчет:',
				value: getMe.U_CashUzsAccount,
			},
			{
				name: 'Денежный счет:',
				value: getMe.U_CashAccount,
			},
		])
		setShowModal(true)
	}

	const handleTypeChange = (e) => {
		const newType = e.target.value
		setForm((prevState) => {
			if (newType === 'UsdToUzs') {
				const usdValue =
					prevState.CashSumUsd ||
					(prevState.CashSumUzs / stateCurrencyRate).toFixed(2)
				return {
					...prevState,
					Type: newType,
					CashSumUsd: usdValue,
					CashSumUzs: (usdValue * stateCurrencyRate).toFixed(2),
				}
			} else {
				const uzsValue =
					prevState.CashSumUzs ||
					(prevState.CashSumUsd * stateCurrencyRate).toFixed(2)
				return {
					...prevState,
					Type: newType,
					CashSumUzs: uzsValue,
					CashSumUsd: (uzsValue / stateCurrencyRate).toFixed(2),
				}
			}
		})
	}

	const handleSumChange = (e) => {
		const value = e.target.value
		setForm((prevState) => {
			if (prevState.Type === 'UsdToUzs') {
				return {
					...prevState,
					CashSumUsd: value,
					CashSumUzs: (value * stateCurrencyRate).toFixed(2),
				}
			} else {
				return {
					...prevState,
					CashSumUzs: value,
					CashSumUsd: (value / stateCurrencyRate).toFixed(2),
				}
			}
		})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => {
				setIsOpenModal(false)
				setShowModal(false)
				setForm({
					DocDate: moment().format('YYYY-MM-DD'),
					Type: 'UsdToUzs',
					CashSumUzs: '',
					CashSumUsd: '',
				})
			}}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<Refund>
				<div className="w-full p-[50px]">
					<h2 className={'text-center mb-5'}>{'Сдача деньги'}</h2>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-10">
						<input
							type="date"
							placeholder="Date"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={form.DocDate}
							onChange={(v) =>
								setForm((prevState) => ({
									...prevState,
									DocDate: v.target.value,
								}))
							}
						/>
						<input
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={stateCurrencyRate}
							onChange={(v) => setStateCurrencyRate(v.target.value)}
						/>
						<input
							type="text"
							placeholder="Cумма"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={
								form.Type === 'UsdToUzs' ? form.CashSumUsd : form.CashSumUzs
							}
							onChange={handleSumChange}
						/>
						<select
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={form.Type}
							onChange={handleTypeChange}
						>
							<option value="UsdToUzs">{'USD'}</option>
							<option value="UzsToUsd">{'UZS'}</option>
						</select>
						<input
							type="text"
							placeholder="Другая валюта"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							disabled
							value={
								form.Type === 'UsdToUzs'
									? form.CashSumUzs + ' UZS'
									: form.CashSumUsd + ' USD'
							}
						/>
					</div>
					<div className="centerCard">
						<button
							className="btnN"
							onClick={() => {
								onClose()
								setForm({
									DocDate: moment().format('YYYY-MM-DD'),
									Type: 'UsdToUzs',
									CashSumUzs: '',
									CashSumUsd: '',
								})
							}}
						>
							{'Нет'}
						</button>
						<button
							className="btnY disabled:cursor-not-allowed disabled:bg-gray-300 hover:bg-green-600 hover:text-white border-[4px] border-green-600 disabled:border-gray-300 disabled:border-gray-300"
							disabled={
								form.DocDate === '' ||
								form.Type === '' ||
								form.CashSumUzs === '' ||
								form.CashSumUsd === '' ||
								isLoading
							}
							onClick={() => {
								handleOpenModal()
							}}
						>
							{'Да'}
						</button>
					</div>
				</div>
				<ConfirmModal
					show={showModal}
					data={confirmationMessage}
					onConfirm={() => {
						setShowModal(false)
						onConfirm(form)
					}}
					onCancel={() => {
						setShowModal(false)
					}}
				/>
			</Refund>
		</Modal>
	)
}

export default memo(RefundModal)
