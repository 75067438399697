import React, { memo, useEffect, useState } from 'react'
import CreateUserStyle from './CreateUserStyle'
import Modal from 'react-modal'
import Button from '../../Button'
import ConfirmModal from '../../ComfirmModal'
import api from '../../../api'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
}

const CreateUserModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
}) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [consumer, setConsumer] = useState('')
	const [passport, setPassport] = useState('')
	const [phone, setPhone] = useState('')
	const [workplace, setWorkplace] = useState('')
	const [address, setAddress] = useState('')
	const [male, setMale] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')
	const [typeSerial, setTypeSerial] = useState([])
	const [series, setSeries] = useState(null)

	useEffect(() => {
		const ref = {
			open: () => setIsOpenModal(true),
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
		getDocumentNumbering()
	}, [])

	const getDocumentNumbering = () => {
		api
			.post('/SeriesService_GetDocumentSeries', {
				DocumentTypeParams: {
					Document: '2',
					DocumentSubType: 'C',
				},
			})
			.then((res) => {
				setTypeSerial(JSON.parse(res.data).value)
			})
	}

	const handleOpenModal = () => {
		setConfirmationMessage([
			{
				name: 'Код клиента:',
				value: cardCode,
			},
			{
				name: 'Клиент:',
				value: consumer,
			},
			{
				name: 'Паспорт:',
				value: passport,
			},
			{
				name: 'Адрес:',
				value: address,
			},
			{
				name: 'Телефон:',
				value: phone,
			},
			{
				name: 'Рабочее место:',
				value: workplace,
			},
			{
				name: 'Пол:',
				value: male,
			},
		])
		setShowModal(true)
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<h2 className="font-bold pb-3">
						{'Создать пользователя'}
						{'!'}
					</h2>
					<div className="flex flex-col gap-4">
						<select
							placeholder="Тип кода клиента"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							value={series}
							onChange={(e) => {
								setSeries(e.target.value)
							}}
						>
							<option value={''}>Выберите тип кода клиента</option>
							{typeSerial.map((v, i) => (
								<option value={v.Series}>{v.Name}</option>
							))}
						</select>
						<input
							type="text"
							placeholder="Код клиента"
							disabled={
								typeSerial.find((item) => item.Series === +series)?.Name !==
								'Вручную'
							}
							className={
								'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full disabled:cursor-not-allowed'
							}
							defaultValue={cardCode}
							onChange={(v) => setCardCode(v.target.value)}
						/>
						<input
							type="text"
							placeholder="Клиент"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={consumer}
							onChange={(v) => setConsumer(v.target.value)}
						/>
						<input
							type="text"
							placeholder="Паспорт"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={passport}
							onChange={(v) => setPassport(v.target.value)}
						/>
						<input
							type="text"
							placeholder="Адрес"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={address}
							onChange={(v) => setAddress(v.target.value)}
						/>
						<input
							type="text"
							placeholder="Телефон"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={phone}
							onChange={(v) => setPhone(v.target.value)}
						/>
						<input
							type="text"
							placeholder="Рабочее место"
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							defaultValue={workplace}
							onChange={(v) => setWorkplace(v.target.value)}
						/>
					</div>

					<div className="flex gap-4 justify-center my-4">
						<div className="flex gap-1">
							<input
								type="radio"
								id="male"
								name="fav_language"
								value="Male"
								onChange={(v) => setMale(v.target.value)}
							/>

							<label htmlFor="male">Мужчина</label>
						</div>
						<div className="flex gap-1">
							<input
								type="radio"
								id="female"
								name="fav_language"
								value="Female"
								onChange={(v) => setMale(v.target.value)}
							/>
							<label htmlFor="female">Женщина</label>
						</div>
					</div>
					<div className="centerCard">
						<button
							className="bg-red-500 text-white py-2 px-4 rounded-md"
							onClick={() => {
								onClose()
								setIsOpenModal(false)
							}}
						>
							{'Нет'}
						</button>
						<Button
							isLoading={isLoading}
							disabled={
								!consumer.length ||
								!male.length ||
								!typeSerial.length ||
								(!cardCode.length &&
									typeSerial.find((item) => item.Series === +series)?.Name ===
										'Вручную')
							}
							onClick={() => {
								handleOpenModal()
							}}
						>
							{'Да'}
						</Button>
					</div>
				</div>
			</CreateUserStyle>
			<ConfirmModal
				show={showModal}
				data={confirmationMessage}
				onConfirm={() => {
					setShowModal(false)
					onConfirm(
						consumer,
						passport,
						male,
						address,
						phone,
						cardCode || series,
						workplace,
						typeSerial.find((item) => {
							console.log(item)
							return item.Series === +series
						})?.Name === 'Вручную',
					)
					setAddress('')
					setSeries('')
					setWorkplace('')
					setCardCode('')
					setConsumer('')
					setMale('')
					setPhone('')
					setPassport('')
				}}
				onCancel={() => {
					setShowModal(false)
				}}
			/>
		</Modal>
	)
}

export default memo(CreateUserModal)
