import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { logsUrl } from '../../api'
import { COMPANY_NAME } from '../../config'
import moment from 'moment'

const Logs = () => {
	const [data, setData] = useState([])
	const [message, setMessage] = useState([])
	const getLogs = () => {
		logsUrl
			.get(`/apis/${COMPANY_NAME}`)
			.then((res) => {
				console.log(res.data)
				setData(res.data)
			})
			.catch((err) => {
				setMessage(err.response.data.message)
			})
	}

	useEffect(() => {
		getLogs()
	}, [])
	return (
		<Layout>
			<div className={'p-16'}>
				<h1 className={'text-xl font-bold mb-8'}>История</h1>
				<div>
					{!data.length ? (
						<p>Нет истории</p>
					) : (
						data.map((item) => {
							return (
								<div className={'p-4 rounded-md border shadow-xl mb-4'}>
									<p className={'text-[14px] text-blue-600 font-bold'}>
										{moment(item.created_at).format('DD-MM-YYYY HH:mm')}
									</p>
									<p className={'text-[16px] text-blue-600 mb-4'}>
										{item.description}
									</p>
									<p className={'text-[18px] text-green-600'}>
										{item.username}
									</p>
									<div>
										<p className={'text-red-500'}>{item.method}</p>
										<p>{item.path}</p>
									</div>
									<code>{JSON.stringify(item.body)}</code>
								</div>
							)
						})
					)}
				</div>
			</div>
		</Layout>
	)
}

export default Logs
