import React, { useEffect, useState } from 'react'

const Progress_bar = ({ bgcolor, progress, height, getRef }) => {
	const Parentdiv = {
		height: height,
		width: '93%',
		backgroundColor: 'whitesmoke',
		borderRadius: 40,
		margin: 50,
	}

	const Childdiv = {
		height: '100%',
		width: `${progress}%`,
		transition: '1500ms',
		backgroundColor: bgcolor,
		borderRadius: 40,
		textAlign: 'right',
	}

	const progresstext = {
		padding: 10,
		color: '#000',
		fontWeight: 600,
	}

	return (
		<>
			<div style={Parentdiv}>
				<div style={Childdiv}>
					<span style={progresstext}>{`${progress}%`}</span>
				</div>
			</div>
		</>
	)
}

export default Progress_bar
